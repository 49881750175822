import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

import { getNextWednesday, getDaysSinceEpoch, MS_IN_A_DAY } from '../utils/time'

export const getNextHuisavond = (date = new Date()) => {
  let candidate = getNextWednesday(date)

  return ((getDaysSinceEpoch(candidate) / 7) | 0) % 2
    ? candidate
    : new Date(candidate.valueOf() + 7 * MS_IN_A_DAY)
}

export default ({ data }) => {
  const stripes = data.allStripesYaml.edges.map(edge => edge.node)
  const bewoners = data.allBewonersYaml.edges
    .map(edge => edge.node)
    .sort((a, b) => a.van - b.van)

  const rows = bewoners.map(bewoner => (
    <tr key={bewoner.id}>
      <td>
        <img
          src={bewoner.image}
          css={{
            width: 32,
            display: 'inline-block',
            verticalAlign: 'middle',
            margin: '0 8px 0 0',
            borderRadius: 16,
          }}
          alt=""
        />
        {bewoner.name}
      </td>
      <td>{stripes.filter(stripe => stripe.name === bewoner.name).length}</td>
    </tr>
  ))

  const dateOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }
  const deadline = getNextHuisavond().toLocaleDateString('nl', dateOptions)

  return (
    <Layout>
      <h2
        css={{
          lineHeight: '1.5',
          span: {
            color: '#777',
            display: 'block',
            fontSize: '.8em',
          },
        }}
      >
        Streepjes
        <span>Deadline: {deadline}</span>
      </h2>
      <table>
        <thead>
          <tr>
            <th>Persoon</th>
            <th>Aantal</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allBewonersYaml(filter: { tot: { eq: null } }) {
      edges {
        node {
          id
          name: title
          image
          van
        }
      }
    }
    allStripesYaml {
      edges {
        node {
          id
          reden: title
          name
          date
        }
      }
    }
  }
`
