export const MS_IN_A_DAY = 24 * 60 * 60 * 1000

export const getDaysSinceEpoch = (date = new Date()) =>
  date.valueOf() / MS_IN_A_DAY

export const getNextWeekday = weekday => (date = new Date()) => {
  let diffDays = weekday - date.getDay()
  while (diffDays < 0) diffDays = diffDays + 7
  return new Date(date.valueOf() + diffDays * MS_IN_A_DAY)
}

export const getNextMonday = getNextWeekday(1)
export const getNextWednesday = getNextWeekday(3)
